import { CustomRangeConfig } from "../../codecs/custom-range-config.codec";
import { EdaPlacementBuilder } from "../lib";
import type { PublicRawMetricConfig } from "../raw-metrics.service";
import { CalculationType, OptimizationObjective, type PublicDimensionConfig } from "../types";

const RAW_METRICS = {
    revenue: {},
    sent_optimize: {},
    sent_samples: {},
    predict_requests: {},
    is_qps_reduced: {},
    is_reduction_reduced: {},
} as const satisfies Record<string, PublicRawMetricConfig>;
export type RawTrafficShapingServerRPMM = keyof typeof RAW_METRICS;

const CALC_IDS = [
    "retained_request_rpmm_with_samples",
    "retained_request_rpmm_without_samples",
    "sent_optimize",
    "sent_samples",
    "sent_total",
    "revenue",
    "predict_requests",
    "is_qps_reduced",
    "is_reduction_reduced",
    "req_reduction_rate",
] as const;
export type TrafficShapingServerRPMMMetrics = (typeof CALC_IDS)[number];
const DIMENSIONS = {
    deploy_id: {
        label: "Deploy ID",
    },
    customer: {
        label: "Customer",
    },
    version: {
        label: "Version",
        limitedToAdminOrDev: true,
    },
    datacenter: {
        label: "Data Center",
    },
    bidderID: {
        label: "Bidder ID",
    },
    model_version: {
        label: "Model Version",
        limitedToAdminOrDev: true,
    },
    regression_buildVersion: {
        label: "Regression Build Version",
        limitedToAdminOrDev: true,
    },
    regression_saveAt: {
        label: "Regression Save At",
        limitedToAdminOrDev: true,
    },
} as const satisfies Record<string, Omit<PublicDimensionConfig, "id">>;

export type TrafficShapingServerRPMMDimensionsIds = keyof typeof DIMENSIONS;

export default new EdaPlacementBuilder()
    .withCustomRange(CustomRangeConfig.dateTime)
    .withTimeRanges([
        "Last 30 Minutes",
        "Last 1 Hour",
        "Last 6 Hours",
        "Last 12 Hours",
        "Last 24 Hours",
        "Today",
        "Yesterday",
        "Last 7 Days",
        "Last 30 Days",
    ])
    .withTimeGranularities(["by_minute", "by_10_minutes", "by_hour", "by_day", "by_week", "by_month", "by_quarter"])
    .withRawMetrics(RAW_METRICS)
    .withCalculatedMetricsIds(CALC_IDS)
    .withCalculatedMetrics({
        revenue: {
            label: "Win revenue",
            formula: m => m.revenue / 1000,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
        },
        sent_optimize: {
            label: "Sent optimize",
            formula: m => m.sent_optimize,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
        },
        sent_samples: {
            label: "Sent samples",
            formula: m => m.sent_samples,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
        },
        sent_total: {
            label: "Sent total",
            formula: m => m.sent_optimize + m.sent_samples,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
        },
        retained_request_rpmm_with_samples: {
            label: "Retained RPMM With Samples",
            formula: m => (m.revenue / m.sent_total) * 1000 * 1000,
            calculationType: CalculationType.SpecialRate,
            objective: OptimizationObjective.Maximize,
        },
        retained_request_rpmm_without_samples: {
            label: "Retained RPMM Without Samples",
            formula: m => (m.revenue / m.sent_optimize) * 1000 * 1000,
            calculationType: CalculationType.SpecialRate,
            objective: OptimizationObjective.Maximize,
        },
        predict_requests: {
            label: "Predicted Requests",
            formula: m => m.predict_requests,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
        },
        is_qps_reduced: {
            label: "Is QPS Reduced",
            formula: m => m.is_qps_reduced,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
        },
        is_reduction_reduced: {
            label: "Is Reduction Reduced",
            formula: m => m.is_reduction_reduced,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
        },
        req_reduction_rate: {
            label: "Request Reduction Rate",
            formula: m => (m.predict_requests - m.sent_total) / m.predict_requests,
            calculationType: CalculationType.ProportionRatio,
            objective: OptimizationObjective.Maximize,
            decimalPlaces: 3,
        },
    })
    .withInitialMetrics([
        "retained_request_rpmm_with_samples",
        "revenue",
        "sent_total",
        "sent_samples",
        "sent_optimize",
    ])
    .withMetricTable({
        columnNames: {
            global: "Global",
        },
        tabMeta: {
            overall: { name: "Overall", explanation: "Overall metrics" },
        },
        tableMapping: {
            overall: {
                global: [...CALC_IDS],
            },
        },
    })
    .withDimensions(DIMENSIONS);
